import React, { Fragment } from "react";
import Footer from "../components/footer";
import "../styles/long1.css";
import "../styles/mysterybox.css";

const Instructions = () => {
  return (<Fragment>
    <h1>Update Your Vehicle Information</h1>
    <div>Below, you'll find the current vehicles registered to your MyTEXpress profile with information such as license plate and toll tag numbers.</div>
    <div>If you'd like to update information on a current vehicle, please click "Update" next to the desired field, type in the new information, and click "Save" when done. If you'd like to add a new vehicle to your MyTEXpress profile, please click the "Add New Vehicle" button and click “Save” when done. Please note that all fields are required when updating or adding new vehicle information.</div>
  </Fragment>)
}

const VehicleInfo = (props) => {
  return (
    <Fragment>
      <div>{props.plateNumber}</div>
      <div>{props.plateState}</div>
      <div>{props.tollTagType}</div>
      <div>{props.tollTagNumber}</div>
      <div>
        <button>Edit</button>
        <a href="#">Remove This Vehicle</a>
      </div>
    </Fragment>
  )
}

const VehiclesInfo = (props) => {
  // TODO: retrieve user's vehicle info to populate VehicleInfo
  return (
    <Fragment>
      <div>Current Vehicle(s):</div>
      {/* TODO: render VehicleInfo for each vehicle */}
      <button>Add New Vehicle</button>
    </Fragment>
  )
}

const TollTagHelp = () => {
  return (
    <Fragment>
      <div>
        Need help finding your toll transponder number? Here are two ways you can
        locate your toll transponder number:
      </div>
      <div>
        <img src="../assets/TollTags.jpg" />
      </div>
      <div>
        Refer to the images above to find the information printed on your toll
        transponder (on your vehicle's windshield. The toll transponder number is
        printed on the side facing the driver in the vehicle.)
      </div>
      <div>
        You can also find your toll transponder number by logging into your
        respective toll transponder account.
        Visit My TollTag Account | Visit My TxTag Account | Visit My EZ TAG Account
      </div>
    </Fragment>
  )
}

const Preferences = () => {
  return (
    <Fragment>
      {/* <Instructions />
      <VehicleInfo />
      <TollTagHelp /> */}
      <Footer />
    </Fragment>)
}

export default Preferences;
